<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('profile.general') }}</span>
      </template>

      <account-setting-general />
    </b-tab>
    <!--/ general tab -->

    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="AlertCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('profile.information') }}</span>
      </template>

      <account-setting-information />
    </b-tab>

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('profile.update-pass') }}</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="MapPinIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('profile.address') }}</span>
      </template>

      <account-setting-address />
    </b-tab>

    <!-- social links -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('profile.birthdate') }}</span>
      </template>

      <account-setting-birth-date />
    </b-tab>

  </b-tabs>
</template>

<script>
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingAddress from './AccountSettingAddress.vue'
import AccountSettingBirthDate from './AccountSettingBirthDate.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'

export default {
  components: {
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingAddress,
    AccountSettingBirthDate,
    AccountSettingInformation,
  },
  data() {
    return {
      options: {},
    }
  },
  methods: {
  },
}
</script>
