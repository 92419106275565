<template>
  <b-card
    :title="$t('birth-date')"
  >
    <b-form @submit.prevent="tryToUpdate">
      <b-row>
        <b-col sm="6">
          <b-form-group>
            <b-form-input
              v-model="$v.localInfo.birthDate.$model"
              type="date"
              :class="{
                'is-invalid': $v.localInfo.birthDate.$error
              }"
            />
            <div
              v-if="$v.localInfo.birthDate.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.localInfo.birthDate.required"
              >{{ $t('errors.required') }}</span>
              <span
                v-if="!$v.localInfo.birthDate.checkAge"
              >{{ $t('errors.date') }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{ $t('form.refresh') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 ml-1"
              :disabled="process"
              type="submit"
            >
              <b-spinner
                v-if="process"
                small
                class="mr-1"
              />
              <span v-show="!process">{{ $t('form.save') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from 'vuelidate/lib/validators'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localInfo: {
        name: this.$store.getters.currentUser.name,
        surname: this.$store.getters.currentUser.surname,
        phone: this.$store.getters.currentUser.phone,
        email: this.$store.getters.currentUser.email,
        country: this.$store.getters.currentUser.country,
        address: this.$store.getters.currentUser.address,
        profileState: this.$store.getters.currentUser.profileState,
        status: this.$store.getters.currentUser.status,
        city: this.$store.getters.currentUser.city,
        birthDate: this.$store.getters.currentUser.birthDate,
        sex: this.$store.getters.currentUser.sex,
      },
    }
  },
  validations: {
    localInfo: {
      birthDate: {
        required,
        checkAge() {
          if (this.localInfo.birthDate) {
            const today = new Date()
            const birthDate = new Date(this.localInfo.birthDate)
            let age = today.getFullYear() - birthDate.getFullYear()
            const m = today.getMonth() - birthDate.getMonth()
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              // eslint-disable-next-line no-plusplus
              age = age--
            }
            if (age < 18) {
              return false
            }
            return true
          }
          return ' '
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      process: 'updateProfileProcess',
      success: 'updateProfileSuccess',
      error: 'updateProfileError',
    }),
  },
  methods: {
    ...mapActions(['updateProfile']),
    tryToUpdate() {
      this.$v.$touch()
      if (this.$v.$error) return

      this.updateProfile({
        name: this.localInfo.name,
        surname: this.localInfo.surname,
        country: this.localInfo.country,
        address: this.localInfo.address,
        sex: this.localInfo.sex,
        city: this.localInfo.city,
        birthDate: this.localInfo.birthDate,
      })
    },
  },
}
</script>
