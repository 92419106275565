<template>
  <b-card>
    <!-- form -->
    <b-form @submit.prevent="formSubmit">
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            :label="$t('form.old-password')"
            label-for="account-old-password"
          >
            <b-form-input
              id="account-old-password"
              v-model="$v.passwordValueOld.$model"
              name="old-password"
              :type="passwordFieldTypeOld"
              :class="{
                'is-invalid': $v.passwordValueOld.$error
              }"
            />
            <div
              v-if="$v.passwordValueOld.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.passwordValueOld.required"
              >{{ $t('errors.required') }}</span>
            </div>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            :label="$t('form.new-password')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="$v.newPasswordValue.$model"
                :type="passwordFieldTypeNew"
                name="new-password"
                :class="{
                  'is-invalid': $v.newPasswordValue.$error
                }"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
              <div
                v-if="$v.newPasswordValue.$error"
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.newPasswordValue.required"
                >{{ $t('errors.required') }}</span>
                <span
                  v-if="!$v.newPasswordValue.password"
                >{{ $t('errors.password') }}</span>
              </div>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            :label="$t('form.confirm-new-password')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="$v.confirmPassword.$model"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                :class="{
                  'is-invalid': $v.confirmPassword.$error
                }"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
              <div
                v-if="$v.confirmPassword.$error"
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.confirmPassword.sameAs"
                >{{ $t('errors.confirm-password') }}</span>
              </div>
            </b-input-group>

          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              :disabled="process"
              variant="outline-secondary"
              class="mt-1"
              @click="reset"
            >
              {{ $t('form.refresh') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 ml-1"
              :disabled="process || loaded"
              type="submit"
            >
              <b-spinner
                v-if="process || loaded"
                small
                class="mr-1"
              />
              <span v-show="!process || !loaded">{{ $t('form.save') }}</span>
            </b-button>
          </div>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, helpers, sameAs,
} from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      correctOldValue: null,
      passwordValueOld: '',
      newPasswordValue: '',
      confirmPassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  validations: {
    passwordValueOld: {
      required,
    },
    newPasswordValue: {
      required,
      password: helpers.regex(
        'password',
        /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{12,}$/,
      ),
    },
    confirmPassword: {
      sameAs: sameAs('newPasswordValue'),
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',

      process: 'updatePasswordProcess',
      error: 'updatePasswordError',
      success: 'updatePasswordSuccess',
    }),
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    success(val) {
      if (val) {
        this.reset()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('password.update'),
            text: this.$t('password.success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('password.update'),
            text: this.$t('password.error'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['updatePassword']),
    reset() {
      this.passwordValueOld = null
      this.confirmPassword = null
      this.newPasswordValue = null

      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    formSubmit() {
      this.$v.$touch()
      if (this.$v.$error) return

      this.tryToConnect()
    },
    async tryToConnect() {
      this.loaded = true
      const data = JSON.stringify({
        email: this.currentUser.email,
        password: this.passwordValueOld,
      })
      this.correctOldValue = await
      this.$http({
        method: 'post',
        url: '/login',
        data,
      })
        .then(() => {
          this.loaded = false
          this.updatePassword({
            password: this.newPasswordValue,
          })
          return true
        })
        .catch(() => {
          this.loaded = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('password.update'),
              text: this.$t('password.wrong-password'),
              icon: 'LockIcon',
              variant: 'warning',
            },
          })
          return false
        })
    },
  },
}
</script>
