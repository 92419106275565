<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col sm="6">
          <b-form-group :label="$t('form.email')">
            <b-form-input
              v-model="localInfo.email"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('form.phone')">
            <b-form-input
              v-model="localInfo.phone"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localInfo: {
        phone: this.$store.getters.currentUser.phone,
        email: this.$store.getters.currentUser.email,
      },
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
