<template>
  <b-card :title="$t('personal')">
    <b-form @submit.prevent="tryToUpdate">
      <b-row>
        <b-col sm="6">
          <b-form-group>
            <b-form-input
              v-model="$v.localInfo.name.$model"
              :placeholder="$t('form.name')"
              :class="{
                'is-invalid': $v.localInfo.name.$error
              }"
            />
            <div
              v-if="$v.localInfo.name.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.localInfo.name.required"
              >{{ $t('errors.required') }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <b-form-input
              v-model="$v.localInfo.surname.$model"
              :placeholder="$t('form.surname')"
              :class="{
                'is-invalid': $v.localInfo.surname.$error
              }"
            />
            <div
              v-if="$v.localInfo.surname.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.localInfo.surname.required"
              >{{ $t('errors.required') }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <b-form-select
              v-model="$v.localInfo.sex.$model"
              :options="sexOptions"
              :class="{
                'is-invalid': $v.localInfo.sex.$error
              }"
            />
            <div
              v-if="$v.localInfo.sex.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.localInfo.sex.required"
              >{{ $t('errors.required') }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              :disabled="process"
            >
              {{ $t('form.refresh') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 ml-1"
              type="submit"
              :disabled="process"
            >
              <b-spinner
                v-if="process"
                small
                class="mr-1"
              />
              <span>{{ $t('form.save') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  required,
} from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localInfo: {
        name: this.$store.getters.currentUser.name,
        surname: this.$store.getters.currentUser.surname,
        phone: this.$store.getters.currentUser.phone,
        email: this.$store.getters.currentUser.email,
        address: this.$store.getters.currentUser.address,
        country: this.$store.getters.currentUser.country,
        profileState: this.$store.getters.currentUser.profileState,
        status: this.$store.getters.currentUser.status,
        city: this.$store.getters.currentUser.city,
        birthDate: this.$store.getters.currentUser.birthDate,
        sex: this.$store.getters.currentUser.sex,
      },
      sexOptions: [
        {
          value: null, text: this.$t('choose-sex'),
        },
        {
          value: 'HOMME', text: this.$t('men'),
        },
        {
          value: 'FEMME', text: this.$t('women'),
        },
      ],
    }
  },
  validations: {
    localInfo: {
      name: {
        required,
      },
      surname: {
        required,
      },
      sex: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      process: 'updateProfileProcess',
      success: 'updateProfileSuccess',
      error: 'updateProfileError',
    }),
  },
  watch: {
    success(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('profile.update'),
            text: this.$t('profile.success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('profile.update'),
            text: this.$t('profile.error'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['updateProfile']),
    formatter(value) {
      return value.toUpperCase()
    },
    tryToUpdate() {
      this.$v.$touch()
      if (this.$v.$error) return

      this.updateProfile({
        name: this.localInfo.name,
        surname: this.localInfo.surname,
        country: this.localInfo.country,
        address: this.localInfo.address,
        sex: this.localInfo.sex,
        city: this.localInfo.city,
        birthDate: this.localInfo.birthDate,
      })
    },
  },
}
</script>
